import { Button, Text, VStack } from '@chakra-ui/react'
import StatusErrorLayout from 'components/layouts/status-error-layout'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

// pages/404.js
export default function Custom404() {
  const router = useRouter()

  const goBack = async () => {
    router.replace('/lessons')
  }

  return (
    <StatusErrorLayout>
      <Head>
        <title>Page not found</title>
      </Head>

      <VStack>
        <Text mb="8">Page not found</Text>
        <Button onClick={goBack}>Go Home</Button>
      </VStack>
    </StatusErrorLayout>
  )
}
